import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import policiesMainIcon from '../images/policies-main-icon.svg';
import PoliciesContent from '../components/PoliciesContent';
import HeroBannerSubpage from '../components/HeroBannerSubpage';

const Privacy = () => (
  <Layout page="privacy">
    <HeroBannerSubpage
      title="Privacy Policy"
      subtitle="Entracker Privacy Policy"
      image={policiesMainIcon}
    />
    <SEO
      title="Privacy Policy"
      subtitle="Simplify your detailing or engineering team work by using Entracker projects and drawings revision managemnt tools"
      keywords={['privacy', 'detailers','project', 'managemnt']}
    />
    <PoliciesContent key="privacy" type="privacy" />
  </Layout>
)

export default Privacy;
